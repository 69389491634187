<template>
  <div class="customerNotificationStepThree">
    <LoadingPlaceholder v-if="loading" />
    <div
      v-else
      v-show="showStep"
    >
      <template v-if="contactList.length == 0">
        <div class="middle-position mb-5">
          <p
            class="red"
            style="text-align: center"
          >
            No Contacts found.
          </p>
        </div>
      </template>
      <template v-else-if="hasMissingLanguages">
        <div class="middle-position mb-5">
          <p
            class="red"
            style="text-align: center"
          >
            There are no translations in the required languages {{ languages }} for this template ({{ selectedTemplate.name }}). Click Refresh, when done.
          </p>
          <router-link
            target="_blank"
            :to="`/smsmanagement/template/${ selectedTemplate.id }`"
            class="btn btn-sm btn-primary ml-auto mr-auto"
          >
            Go to Message Management
          </router-link>
          <button
            class="btn btn-sm btn-primary mt-2 ml-auto mr-auto"
            type="button"
            @click="checkLanguages"
          >
            <font-awesome-icon
              class="mr-1"
              icon="sync-alt"
            />
            <span>
              {{ $t('refresh') }}
            </span>
          </button>
        </div>
      </template>
      <template
        v-else
      >
        <div class="row mb-4">
          <div class="col-2 alignCenter">
            <div class="cell">
              <font-awesome-icon
                class="mb-4 bigText"
                icon="user-tie"
                style="vertical-align: middle;"
              />
              <br>
              <span>{{ $t('support') }}</span>
            </div>
          </div>
          <div class="col-7 mt-2">
            <div class="cell">
              <template
                v-for="(message, index) in messageList"
              >
                <div
                  :key="'msg' + index"
                  :class="['bubble', {'mt-3': index != 0}]"
                >
                  <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('template') }}:</label><span>{{ selectedTemplate.installationType }}:&nbsp;{{ selectedTemplate.name }}</span>
                  <template v-if="message.subject">
                    <hr>
                    <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('subject') }} ({{ message.language }}):</label><span>{{ message.subject }}</span>
                  </template>
                  <hr>
                  <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('message') }} ({{ message.language }}):</label><span>{{ message.message }}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="col-1">
            <div class="cell">
              <font-awesome-icon
                class="mt-4 mediumText"
                icon="long-arrow-alt-right"
                style="vertical-align: middle;"
              />
            </div>
          </div>
          <div class="col-2 alignCenter">
            <div class="cell">
              <font-awesome-icon
                class="mb-4 bigText"
                icon="user-tie"
                style="vertical-align: middle;"
              />
              <br>
              <div>
                <span>{{ getContactsCount }}</span>
                <font-awesome-icon
                  class="ml-2"
                  icon="external-link-square-alt"
                  @click="openRecipientsModal"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <button
        class="btn btn-sm btn-primary float-left"
        type="button"
        @click="prevStep"
      >
        <font-awesome-icon
          class="mr-1"
          icon="arrow-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </button>
      <button
        class="btn btn-sm btn-primary float-right"
        type="button"
        :disabled="contactList.length == 0 || hasMissingLanguages"
        @click="nextStep"
      >
        <span>
          {{ $t('send') }}
        </span>
        <font-awesome-icon
          class="mr-1"
          icon="arrow-right"
        />
      </button>
    </div>
    <SweetModal
      ref="recipients"
      blocking
      title="Recipients"
      class="overflowHidden"
    >
      <ul class="list-group scrollable">
        <template
          v-for="(item, index) in contactList"
        >
          <li
            :key="'li' + index"
            class="list-group-item list-group-item-action"
          >
            <font-awesome-icon
              class="mr-3 float-left"
              icon="user"
            />
            {{ concatName(item) }}
          </li>
        </template>
      </ul>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'CustomerNotificationStepThree',
  components: {
    SweetModal
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    stepOneObj: {
      type: Object,
      required: true
    },
    selectedTemplate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showStep: false,
      loading: true,
      contactList: null,
      languages: [],
      hasMissingLanguages: true,
      activeContact: null,
      messageList: null
    }
  },
  computed: {
    getContactsCount () {
      return this.contactList.length + ' Recipients';
    }
  },
  watch: {
    active (val) {
      this.showStep = val;
    }
  },
  created () {
    this.showStep = this.active;
    this.getAllContactsAndInstallations()
  },
  methods: {
    openRecipientsModal () {
      this.$refs.recipients.open();
    },
    getAllContactsAndInstallations () {
      this.axios.post('/Issue/GetAllContactsWithJiraFilter', this.stepOneObj)
        .then((response) => {
          this.contactList = response.data;
        })
        .finally(() => {
          if(this.contactList && this.contactList.length > 0) {
            this.axios.post(`/SmsManagement/HasTemplateMissingContactsLanguages?templateId=${ this.selectedTemplate.id }`, this.contactList)
              .then((response) => {
                this.languages = response.data;
                this.hasMissingLanguages = this.languages.length > 0;
                if(!this.hasMissingLanguages) {
                  this.getMessages();
                }
              })
              .finally(() => {
                this.loading = false;
            });
          }
          else {
            this.loading = false;
          }
        });
    },
    getMessages () {
      this.loading = true;
      this.axios.get(`/SmsManagement/GetMessagesByTemplateId?templateId=${ this.selectedTemplate.id }`)
        .then((response) => {
          this.messageList = response.data;
        })
        .finally(() => {
          this.loading = false;
      });
    },
    checkLanguages () {
      this.loading = true;
      this.axios.post(`/SmsManagement/HasTemplateMissingContactsLanguages?templateId=${ this.selectedTemplate.id }`, this.contactList)
        .then((response) => {
          this.languages = response.data;
          this.hasMissingLanguages = this.languages.length > 0;
          if(!this.hasMissingLanguages) {
            this.getMessages();
          }
        })
        .finally(() => {
          this.loading = false;
      });
    },
    nextStep () {
      this.$emit("goStepFour", this.contactList)
    },
    prevStep () {
      this.$emit("goStepTwo", this.stepOneObj)
    },
    concatName (contact) {
      return contact.firstname + ' ' + contact.surname;
    },
    setAsActive (contact) {
      this.activeContact = contact;
    }
  }
}
</script>

<style scoped>
.middle-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scrollable {
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
